import React, { Children } from 'react'
import { Box, Flex, List, ListItem, Text, SimpleGrid, useTheme, useToken, Tooltip } from '@chakra-ui/react'
import { LinkIcon, InfoIcon, ChatIcon, EmailIcon } from '@chakra-ui/icons'
import { StaticImage } from 'gatsby-plugin-image'
import { PageLayout } from '../components/PageLayout'
import { Hero } from '../components/Hero'
import { Link } from '../components/Link'

export const Head = () => (
    <>
        <title>WPL Mobile App | Orientier Proposal Hub for WPL Digital Branch</title>
        <meta name="robots" content="noindex" />
    </>
)

const hero = <Hero heading="WPL Mobile App" subheading="New tools for a more holistic digital experience." />

const iconSize = '2rem'
const testFlightLogo = () => (
    <StaticImage src="../images/testflight_logo.png" alt="TestFlight App Logo" placeholder="blurred" width={48} />
)

const PlatformLogo = ({ viewBox, children }) => (
    <Box
        as="svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox={viewBox}
        mb={4}
        style={{ height: '4rem', width: 'auto' }}
    >
        {children}
    </Box>
)

const appleLogo = () => (
    <PlatformLogo viewBox="0 0 800 1000">
        <Box
            as="path"
            fill="oriGrayscale.800"
            d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"
        />
    </PlatformLogo>
)

const androidLogo = () => (
    <PlatformLogo viewBox="-147 -70 294 345">
        <Box as="g" fill="oriGrayscale.800">
            <use strokeWidth="14.4" xlinkHref="#b" stroke="oriGrayscale.50" />
            <use xlinkHref="#a" transform="scale(-1,1)" />
            <g id="a" stroke="oriGrayscale.50" strokeWidth="7.2">
                <rect rx="6.5" transform="rotate(29)" height="86" width="13" y="-86" x="14" />
                <rect id="c" rx="24" height="133" width="48" y="41" x="-143" />
                <use y="97" x="85" xlinkHref="#c" />
            </g>
            <g id="b">
                <ellipse cy="41" rx="91" ry="84" />
                <rect rx="22" height="182" width="182" y="20" x="-91" />
            </g>
        </Box>
        <Box as="g" stroke="oriGrayscale.50" strokeWidth="7.2" fill="oriGrayscale.50">
            <path d="m-95 44.5h190" />
            <circle cx="-42" r="4" />
            <circle cx="42" r="4" />
        </Box>
    </PlatformLogo>
)

const TestingTrack = ({ logo, children }) => (
    <Flex flexDir="column" alignItems="center" mt={4}>
        <Flex p={4}>{logo}</Flex>
        <TestingSteps>{children}</TestingSteps>
    </Flex>
)

const TestingSteps = ({ children }) => {
    const arrayChildren = Children.toArray(children)

    return (
        <Flex
            flexDir="column"
            alignItems="center"
            border="1px solid"
            borderColor="oriGrayscale.100"
            borderRadius={4}
            gap={6}
            p={8}
        >
            {arrayChildren.map((child, index) => {
                const isLast = index === arrayChildren.length - 1

                return (
                    <React.Fragment key={index}>
                        {child}
                        {isLast ? (
                            ''
                        ) : (
                            <Flex color="oriGrayscale.200" fontSize="2xl">
                                ↓
                            </Flex>
                        )}
                    </React.Fragment>
                )
            })}
        </Flex>
    )
}

const TestingStep = ({ linkDestination, disabled, children }) => {
    const commonProps = {
        border: '1px dashed',
        borderColor: disabled ? 'oriGrayscale.200' : 'oriTeal.300',
        color: disabled ? 'oriGrayscale.200' : 'inherit',
        p: 6,
        borderRadius: 4,
        textAlign: 'center',
        flexDir: 'column',
        gap: 4,
        alignItems: 'center',
        maxWidth: '3xs',
        _hover: disabled ? { cursor: 'not-allowed' } : {},
        sx: {
            img: {
                filter: 'grayscale(100%)',
            },
            '&:hover img': {
                filter: 'none',
            },
        },
    }

    return (
        <>
            {linkDestination ? (
                <Link
                    destination={linkDestination}
                    {...commonProps}
                    display="flex"
                    _hover={disabled ? { cursor: 'not-allowed' } : { borderStyle: 'solid', background: 'oriTeal.50' }}
                    _after={{ content: 'none' }}
                >
                    {children}
                </Link>
            ) : (
                <Flex {...commonProps}>{children}</Flex>
            )}
        </>
    )
}

const AppPage = () => {
    const {
        constants: { LIST_UL_BULLETED },
    } = useTheme()

    return (
        <PageLayout hero={hero}>
            <Box fontSize="md" maxWidth="container.md" mx="auto" p={8}>
                <Text>The new iOS and Android apps are a key part of the WPL digital branch.</Text>
                <Text>
                    We are currently developing proof-of-concept apps for both platforms that can interact with a
                    dedicated Evergreen instance and a Drupal-powered CMS/hub with mock WPL data.
                </Text>
                <Text>Why are we doing this now?</Text>
                <List variant={LIST_UL_BULLETED}>
                    <ListItem>To demonstrate technical competence</ListItem>
                    <ListItem>To demonstrate our engagement with and interest in the WPL project</ListItem>
                    <ListItem>To get a jump start on the agile development process</ListItem>
                    <ListItem>
                        To get an early read on any bottlenecks and focus areas (starting with simple-but-integrated
                        systems rather than developing siloed systems fur future integration)
                    </ListItem>
                    <ListItem>To inform the discovery process</ListItem>
                    <ListItem>
                        To give both teams useful, tangible points of reference as we discuss planned functionality and
                        design
                    </ListItem>
                </List>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={16} mt={12}>
                    <TestingTrack logo={appleLogo()}>
                        <TestingStep linkDestination="https://itunes.apple.com/us/app/testflight/id899247664">
                            <Box width={iconSize}>{testFlightLogo()}</Box>
                            <Text>Download the TestFlight App</Text>
                        </TestingStep>
                        <TestingStep disabled>
                            <LinkIcon boxSize={iconSize} />
                            <Text>Access the WPL iOS app via private testing link</Text>
                        </TestingStep>
                        <TestingStep disabled>
                            <ChatIcon boxSize={iconSize} />
                            <Text>Provide feedback</Text>
                        </TestingStep>
                    </TestingTrack>
                    <TestingTrack logo={androidLogo()}>
                        <TestingStep linkDestination="mailto:dev@orientier.com?subject=WPL Android App Testing Request">
                            <EmailIcon boxSize={iconSize} position="relative" top={1} />
                            <Text>
                                Provide your email{' '}
                                <Box as="span" whiteSpace="nowrap" display="inline-flex" alignItems="center">
                                    address &nbsp;
                                    <Tooltip label="Must be a Google Account (@gmail.com) or Google Workspace account">
                                        <InfoIcon />
                                    </Tooltip>
                                </Box>
                            </Text>
                        </TestingStep>
                        <TestingStep disabled>
                            <LinkIcon boxSize={iconSize} />
                            <Text>Access the WPL Android app via private testing link</Text>
                        </TestingStep>
                        <TestingStep disabled>
                            <ChatIcon boxSize={iconSize} />
                            <Text>Provide feedback</Text>
                        </TestingStep>
                    </TestingTrack>
                </SimpleGrid>
            </Box>
        </PageLayout>
    )
}

export default AppPage
